import React from 'react'
import { Cap } from './components/Cap/Cap'
import { Corusel } from './components/Corusel/Corusel';
import { bgItem1, bgItem2, phone, card } from './assets';
import { Footer } from './components/Footer/Footer';
import './scss/app.scss';

export const App = () => {

  return (
    <main className='container'>
      <Cap/>
      <Corusel>
        <div className='item item-s'>
          <img src={bgItem1} alt=''/>
          <div className='item-s__content'>
            <div className='item-s__content-text'>
              <h2 className='header item-s__content-hmb'>NBloom</h2>
              <p className='paragraph item-s__content-pmb'>
                Наша компания занимается созданием 3D-моделей вашего товара, с возможностью, преобразования его в NFT, 
                который вы можете использовать, как дополняющий товар, к своему основному.
              </p>

              <p className='paragraph-gray paragraph item-s__content-pmb'>
                Мы предоставляем свое андроид приложение NFT-кошелька, 
                в котором каждый может увидеть купленный товар в виде 3D-модели.
              </p>
            </div>

            <div className='item-s__content-img'>
              <img src={phone} alt=''/>
            </div>
          </div>
        </div>
        <div className='item item-s'>
          <img src={bgItem2} alt=''/>

          <div className='item-s__content'>
            <div className='item-s__content-text'>
              <h2 className='header item-s__content-hmb'>Товары</h2>
              <p className='paragraph item-s__content-pmb'>
                Наши NFT поддерживают стандарт ERC-1155 и ERC-721, что делает доступной их перепродажу. 
              </p>

              <p className='paragraph-gray paragraph item-s__content-pmb'>
                3D-модель может как полностью копировать внешний вид вашего товара, так и полностью отличаться от него, что позволяет включить в процесс создания вашего дополняющего 
                NFT товара возможности подключения анимаций и элементов окружения.
              </p>

            </div>

            <div className='item-s__content-img-c'>
              <img src={card} alt=''/>
            </div>
          </div>
        </div>
      </Corusel>

      <Footer/>
    </main>
  )
}
