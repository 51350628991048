import React, { 
    Children, cloneElement, useEffect, useState 
} from 'react';

import {
    SCREEN_MD,
    SCREEN_SM, SCREEN_XLL
} from '../../constant';


import { arrowLeft, arrowRigth } from '../../assets';
import {useResize} from '../../hooks';
import './Carousel.scss';


export const Corusel = ({children}) => {
    const {PATH_WIDHT, isScreenSm, isScreenMd} = useResize();
    const [pages, setPages] = useState([]);
    const [offset, setOffset] = useState(0);
    

    useEffect(() => {
        setPages(
            Children.map(children, (child) => {
                return cloneElement(child, {
                    style:{
                        heigh: "100%",
                        minWidth: "100%",
                        maxWidth: "100%"
                    }
                })
            })
        )
    }, [children])

    const hanlerLeftArrowClick = () => {
        setOffset((currentOffset) => {            
            if (isScreenMd){
                return Math.min(
                    currentOffset + (1600 / 640 * 100), 0
                );
            } else if (isScreenSm || PATH_WIDHT <=  SCREEN_SM){
                return Math.min(
                    currentOffset + (1600 / 420 * 100), 0
                );
            }
        })
    }

    const hanlerRigthArrowClick = () => {
        setOffset((currentOffset) => {
            if(PATH_WIDHT >= SCREEN_MD && PATH_WIDHT <= SCREEN_XLL){
                console.log(SCREEN_MD <= PATH_WIDHT && PATH_WIDHT <= SCREEN_XLL)
                return Math.max(
                    currentOffset - (1600 / 1920 * 100), -(1600 / 1920 * 100)
                );
            } else if(PATH_WIDHT >= SCREEN_SM && PATH_WIDHT <= SCREEN_MD){
                return Math.max(
                    currentOffset - (1600 / 640 * 100), -(640 / 640 * 100)
                );
            } else if (PATH_WIDHT <= SCREEN_SM){
                return Math.max(
                    currentOffset - (1600 / 420 * 100), -(420 / 420 * 100)
                );
            }
        })
    }

    return (
        <div className='corusel-main-contaner'>
            <div className='corusel-window'>
                <div className='corusel-all-pages-container' style={{transform: `translateX(${offset}vw)`}}>
                    {pages}
                </div>
            </div>
            <div className='corusel-arrows'>
                <img className='corusel-arrows-item' src={arrowLeft} onClick={hanlerLeftArrowClick} alt=''/>
                <img className='corusel-arrows-item' src={arrowRigth} onClick={hanlerRigthArrowClick} alt=''/>
            </div>
        </div>
    )
}
